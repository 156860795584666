import bankValidator from 'us-bank-account-validator'

const Validator = function() {
  this.form = null;
  this.apiErrors = [];
  this.rules.__VALIDATOR_OBJECT = this;
}

Validator.prototype.rules = {

  einNumber(message) {
    if (!message) {
      message = "Invalid EIN Number format.";
    }

    return v => {
      return /^[0-9]{2}-[0-9]{7}$/.test(v)||message;
    };
  },

  zipcode(message) {
    if (!message) {
      message = "Invalid ZIP code format.";
    }

    return v => {
      return /^[0-9]{5}(?:-[0-9]{4})?$/.test(v)||message;
    };
  },

  routingNumber(message) {
    if (!message) {
      message = "Invalid Routing Number format.";
    }

    return v => {
      const routingValidation = bankValidator.routingNumber(v);
      return routingValidation.isValid||message;
    };
  },

  accountNumber(message) {
    if (!message) {
      message = "Invalid Account Number format.";
    }

    return v => {
      const accountValidation = bankValidator.accountNumber(v);
      return accountValidation.isValid||message;
    };
  },

  url(message) {
    if (!message) {
      message = "Invalid URL format.";
    }

    return v => {
      return /^\w+([.\-+]?\w+)*\+*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(v)||message;
    };
  },

  email(message) {
    if (!message) {
      message = "Invalid email address format.";
    }

    return v => {
      return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v)||message;
    };
  },

  phone(message) {
    if (!message) {
      message = "Invalid phone number format";
    }

    return v => {
      return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v)||message;
    };
  },

  matches(object, propertyPath, message) {
    return v => {
      let compare = object;
      const props = propertyPath.split(".");

      props.forEach(prop => {
        compare = compare[prop];
      });

      return compare === v || message;
    };
  },

  maxLength(length, message) {
    if (!message) {
      message = `Max ${length} characters allowed.`;
    }

    return v => {
      return v && v.length > length ? message : true;
    };
  },

  minLength(length, message) {
    if (!message) {
      message = `Min ${length} characters allowed.`;
    }

    return v => {
      return v && v.length < length ? message : true;
    };
  },

  required(message) {
    if (!message) {
      message = "This field is required.";
    }

    return v => {
      return !v || (v.length && v.length < 1) ? message : true;
    };
  },

  username(message) {
    if (!message) {
      message = "Only letters, numbers and underscore are allowed.";
    }

    return v => {
      return /^[a-zA-Z0-9_]+$/.test(v) ? true : message;
    };
  }
};

Validator.prototype.formIsValid = function(form) {
  this.form = form;
  return form.validate();
};

export default Validator;