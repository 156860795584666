var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: "" } }),
      _c(
        "v-content",
        { staticClass: "login-wrap" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "form-sm",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.logIn.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c("tev-pagetitle", {
                            attrs: {
                              title: "Welcome",
                              subtitle:
                                "Sign in to your Tenant Evaluation account.",
                            },
                          }),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      xs12: "",
                                      rules: _vm.rules.email,
                                      label: "Email",
                                      type: "text",
                                      autocomplete: "off",
                                      box: "",
                                      autofocus: _vm.$isDesktop(),
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      xs12: "",
                                      rules: _vm.rules.password,
                                      label: "Password",
                                      type: "password",
                                      required: "",
                                      box: "",
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      class: { "blue--text": _vm.loading },
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                        large: "",
                                        block: "",
                                        depressed: "",
                                        loading: _vm.loading,
                                        disabled: _vm.loading,
                                      },
                                      on: { click: _vm.logIn },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Login\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-flex", { attrs: { xs12: "", sm6: "" } }),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm6: "",
                                    "text-sm-right": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-2 mx-0 text-none",
                                      attrs: {
                                        flat: "",
                                        color: "primary",
                                        to: "/forgot",
                                      },
                                    },
                                    [_vm._v("Forgot your password?")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    "mt-5": "",
                                    "text-xs-left": "",
                                  },
                                },
                                [
                                  _c("p", [
                                    _c("strong", [_vm._v("DATA SECURITY:")]),
                                    _c("br"),
                                    _vm._v(
                                      "Precise Background Technologies complies with the Fair Credit Reporting Act and the Federal Trade Commission Act which require that we provide reasonable security for your social security, date of birth, full name, etc. Your data will be encrypted using 256 bits and will be deleted from our system after 30 days."
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("strong", [_vm._v("NOTICE:")]),
                                    _c("br"),
                                    _vm._v(
                                      "The use of this system is restricted. Only authorized users may access this system. All Access to this system is logged and regularly monitored for computer security purposes. Any unauthorized access to this system is prohibited and is subject to criminal and civil penalties under Federal Laws including, but not limited to, the Computer Fraud and Abuse Act and the National Information Infrastructure Protection Act."
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    "my-5": "",
                                    "text-xs-center": "",
                                    "body-1": "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "©" +
                                      _vm._s(new Date().getFullYear()) +
                                      " Tenant Evaluation LLC. "
                                  ),
                                  _c("span", { staticClass: "grey--text" }, [
                                    _vm._v(
                                      "v." +
                                        _vm._s(_vm.appVersion) +
                                        _vm._s(_vm.appMode)
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }