<template>
  <v-app>
    <tev-navbar title="" />
    <v-content class="login-wrap">
      <v-layout row wrap pt-4>
        <v-flex xs12>
          <v-form ref="form" lazy-validation class="form-sm" @submit.prevent="logIn">
            <v-container>
              <tev-pagetitle title="Welcome" subtitle="Sign in to your Tenant Evaluation account." />
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <v-text-field xs12 v-model="email" :rules="rules.email" label="Email" type="text" autocomplete="off" box :autofocus="$isDesktop()"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-text-field xs12 v-model="password" :rules="rules.password" label="Password" type="password" required box></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-btn type="submit" color="primary" large block depressed @click="logIn" :loading="loading" v-bind:class="{'blue--text': loading}" :disabled="loading">
                    Login
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6>
                  <!--v-btn flat color="grey" class="px-2 mx-0 text-none" @click="rememberMe=!rememberMe">
                    <toggle-button style="pointer-events: none;" :value="rememberMe" color="#2196F3" :font-size="14" :width="34" :height="18" :sync="true" class="mr-2" /> Remember me
                  </v-btn-->
                </v-flex>
                <v-flex xs12 sm6 text-sm-right>
                  <v-btn flat color="primary" to="/forgot" class="px-2 mx-0 text-none">Forgot your password?</v-btn>
                </v-flex>
                <v-flex xs12 sm12 mt-5 text-xs-left>
                  <p><strong>DATA SECURITY:</strong>
                    <br>Precise Background Technologies complies with the Fair Credit Reporting Act and the Federal Trade Commission Act which require that we provide reasonable security for your social security, date of birth, full name, etc. Your data will be encrypted using 256 bits and will be deleted from our system after 30 days.</p>
                  <p><strong>NOTICE:</strong>
                    <br>The use of this system is restricted. Only authorized users may access this system. All Access to this system is logged and regularly monitored for computer security purposes. Any unauthorized access to this system is prohibited and is subject to criminal and civil penalties under Federal Laws including, but not limited to, the Computer Fraud and Abuse Act and the National Information Infrastructure Protection Act.</p>
                </v-flex>
                <v-flex xs12 sm12 my-5 text-xs-center body-1>&copy;{{ new Date().getFullYear() }} Tenant Evaluation LLC. <span class="grey--text">v.{{ appVersion }}{{ appMode }}</span></v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import config from '@/config';

import {
  mapGetters
} from 'vuex'
import Validator from '../libs/validator'

export default {
  data() {
      const validator = new Validator();
      return {
        loading: false,
        email: null,
        password: null,
        rememberMe: false,
        rules: {
          email: [
            validator.rules.required("E-mail is required."),
            validator.rules.email("Invalid email."),
          ],
          password: [
            validator.rules.required("Password is required."),
            //validator.rules.minLength(6, "Password should be at least 6 characters."),
          ],
        }
      }
    },
    beforeMount() {
      if (this.$route.query.redirect && this.$route.query.redirect != this.$route.path) {
        window.location.href = `${config.loginUrl}?redirect_uri=${window.location.origin}${this.$route.query.redirect}`;
      }
      else {
        window.location.href = `${config.loginUrl}?redirect_uri=${window.location.origin}`;
      }
    },
    computed: {
      ...mapGetters({
        currentUser: 'currentUser',
        appVersion: 'appVersion'
      }),
      appMode() {
        switch (config.nodeEnv) {
          case 'production':
            return ''
          case 'staging':
            return '.qa-r2'
          default:
            return '.localhost'
        }
      }
    },
    methods: {
      loginFailed(message) {
        this.$store.dispatch('logout')
        delete localStorage.token
        this.alertError(message)
      },
      alertError(message) {
        this.$events.$emit('showSnackbar', {
          message: message,
          color: 'red'
        });
      },
      logIn() {
        const _this = this
        if (!this.$refs.form.validate()) {
          return;
        }

        this.loading = true
        this.$http.post('/manager/authenticate', {
            username: _this.email,
            password: _this.password,
            rememberMe: _this.rememberMe
          })
          .then(response => {
            _this.loading = false

            if (!response.data || !response.data.id_token) {
              return _this.loginFailed('Login failed!')
            }

            localStorage.token = response.data.id_token
            _this.$store.dispatch('login')

            let redirect = '/'
            if (_this.$route.query.redirect && _this.$route.query.redirect != this.$route.path) {
              redirect = _this.$route.query.redirect
            }

            _this.$nextTick(() => {
              _this.$router.push(redirect)
            })
          })
          .catch(e => {
            _this.loading = false
            _this.loginFailed(e.error || 'An error occurred while processing your request.')
          })
      }
    }
}
</script>
<style>
.login-wrap .v-content__wrap {
    max-width: 100%;
    margin: 0 auto;
}
</style>